import {Button, Row, Container} from 'react-bootstrap';

// @ts-ignore
const Question = ({options,  question,  onAnswer, selectedOptions }) => {
    const handleOptionClick = (optionId : number) => {
        onAnswer(question.id, optionId);
    };

    return (
        <><Container className={"p-3"} style={{height: "180px"}} >
            <h2 style={{textAlign: "center"}}>{question.text}</h2>
        </Container>
            <Container>
            {options.map((option: { id: number; text: string; }) => (
                <Row style={{marginBottom: "5%"}} key={option.id}>
                    <Button style={{height: "50px"}}  key={option.id} onClick={() => handleOptionClick(option.id)} variant={selectedOptions?.answer === option.id? 'success' : "outline-primary"}>
                        {option.text}
                    </Button>
                </Row>

            ))}

        </Container></>
    );
};

export default Question;