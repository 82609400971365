import React, {useEffect, useState} from 'react';
import Smiley from "./Smiley";
import RecordVoice from "./RecordVoice";
import {useLocation} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import Medication from "./Medication";

interface RecordingMainProps {
    languageData: any;
}

const RecordingPage: React.FC<RecordingMainProps> = ({ languageData }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const username = params.get('username');

    const [currentStep, setCurrentStep] = useState<'medication' | 'smiley' | 'recording'>('medication');
    const [smileyValue, setSmileyValue] = useState<string>("");
    const [smileyValueForRecordVoice, setSmileyValueForRecordVoice] = useState<string>("");
    const [medication, setMedication] = useState<string>("");

    const handleSmileyClick = (value: string) => {
        setSmileyValue(value);
        setCurrentStep('recording'); // Move to the recording step after smiley is clicked
    };

    const onSetTime = (value: string) => {
        setMedication(value);
        setCurrentStep('smiley'); // Move to the smiley step after setting medication time
    }

    useEffect(() => {
        setSmileyValueForRecordVoice(smileyValue); // Update smileyValueForRecordVoice whenever smileyValue changes
    }, [smileyValue]);

    return (
        <>
            {currentStep === 'medication' && (
                <Medication onSetTime={onSetTime} languageData={languageData} />
            )}
            {currentStep === 'smiley' && (
                <Container className={"p-3"}>
                    <Row style={{ margin: '0 0 3% 0' }}>
                        <Col><h2 className={"display-6"} style={{ textAlign: "center" }}>{languageData.instructionSmiley}</h2></Col>
                    </Row>
                    <Smiley onClick={handleSmileyClick} />
                </Container>
            )}
            {currentStep === 'recording' && (
                <RecordVoice
                    languageData={languageData}
                    identifier={smileyValueForRecordVoice}
                    username={username}
                    instructionText={languageData.instructionText1}
                    medication={medication}
                />
            )}
        </>
    );
};

export default RecordingPage;