import React, {useState, useEffect} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from './components/Home';
import Questionnaire from "./components/Questionnaire";
import Recording from "./components/RecordingMain";
import ErrorPage from "./components/Error";
import Therapist from './components/TherapistPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import PatientPage from "./components/PatientPage";
import enTranslation from "./assets/lang/en.json";
import deTranslation from "./assets/lang/de.json";
import frTranslation from "./assets/lang/fr.json";
import itTranslation from "./assets/lang/it.json";
import ImagePage from "./components/ImagePage";
import Medication from "./components/Medication";

const App: React.FC = () =>{
    const [language, setLanguage] = useState<string>('en');
    const [languageData, setLanguageData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const [error, setError] = useState<unknown>(null); // Error state

    useEffect(() => {
        const checkUsername = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const usernameParam = urlParams.get('username');

            if (usernameParam) {
                setLoading(true);
                setError(false);
                try {
                    const response = await fetch(`https://recpd.ch/api/patient/checkUsername/${usernameParam}`);
                    if (!response.ok) {
                        throw new Error('Failed to check if user is present')
                    }
                    const data = await response.json();
                    if(!data.exists){
                        throw new Error('User was not found')
                    }

                } catch (error) {
                    setError(error);
                    console.log(error);
                } finally {
                setLoading(false);
            }
            }
        }
        checkUsername().then();
    }, []);

    useEffect(() => {
        const checkLanguage = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const usernameParam = urlParams.get('username');
	    console.log(usernameParam);
            if (usernameParam) {
                setLoading(true);
                setError(false);
                try {
                    const response = await fetch(`https://recpd.ch/api/patient/checkLanguage/${usernameParam}`);
                    if (!response.ok) {
                        throw new Error('Failed to check if user is present')
                    }
                    const data = await response.json();
                   setLanguage(data.language)


                } catch (error) {
                    setError(error);
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            }
        }
        checkLanguage();

    }, []);

    const fetchDataLanguage = (lang: string) => {
        setLoading(true); // Set loading state to true
        setError(null); // Reset error state
        try {
	    let data = {};
            if(lang === undefined || null){
                console.log("no language found");
                data = enTranslation;
            }
            if(lang.toLowerCase() == "de"){
                data = deTranslation;
            }else if(lang.toLowerCase() == "en"){
                data = enTranslation;
            }else if(lang.toLowerCase() == "it"){
                data = itTranslation;
            }else if(lang.toLowerCase() == "fr"){
                data = frTranslation;
            }
	    // const data = enTranslation;
            //const response = await fetch(`lang/${lang}.json`);
            //if(!response.ok){
            //    throw new Error('Failed to fetch language data');
            //}

            //const data = await response.json();
            setLanguageData(data);

        }catch(error){
            setError(error)
            console.error('Error fetching language data:', error);
        }
        finally {
            setLoading(false)
        }
    };




    useEffect(() =>{

        fetchDataLanguage(language);
    }, [language]);
    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (error) {
        return <h1>{error.toString()}</h1>;
    }

    return(
  <BrowserRouter>
    <Routes>
        <Route path="/" element = {<Home languageData={languageData}/>}/>
        <Route path="/recording" element = {<Recording languageData={languageData}/>}/>
        <Route path="/image" element = {<ImagePage languageData={languageData}/>}/>
        <Route path="/questionnaire" element = {<Questionnaire languageData={languageData}/>}/>
        {/*<Route path ="/therapist" element={<Therapist languageData={languageData}/>}/>*/}
        <Route path="/error" element={<ErrorPage languageData={languageData}/>}/>
        {/*<Route path="/patient" element={<PatientPage languageData={languageData}/>}/>*/}
    </Routes>

  </BrowserRouter>
)}


export default App;
