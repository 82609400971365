import React from "react";
import {Button, Container, Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

interface FinishPageProps {
    languageData: any;
    username : string | null;
}
const FinishPage : React.FC<FinishPageProps> = ({languageData, username}) => {
    const navigate = useNavigate();

    const closeModal = () => {
        navigate(`/?username=${username}`)
    };
    return(
        <Container className={"p-5"}>
            <Modal.Dialog>
                <Modal.Header style={{justifyContent: "center"}}>
                    <Modal.Title >
                        <p>{languageData.finished}</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{textAlign: "center"}}>{languageData.finishedText}</p>
                </Modal.Body>
                <Modal.Footer style={{justifyContent: "center"}}>
                    <Button style={{width : "200px", height: "62px"}}  variant="primary" onClick={closeModal}>{languageData.close}</Button>
                </Modal.Footer>
            </Modal.Dialog>

        </Container>)


}
export default FinishPage