import React, {useState} from "react";
import {Container, Row, Col, Form, Button} from "react-bootstrap";

interface MedicationProps {
    onSetTime : (selectedTime : string) => void;
    languageData: any;
}

const Medication: React.FC<MedicationProps> = ({onSetTime, languageData}) => {
    const [hour, setHour] = useState<string>('');
    const [minute, setMinute] = useState<string>('');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const time = `${hour}_${minute}`;
        onSetTime(time);
    };

    return (
        <Container style={{ marginTop: '3%' }}>
            <h2 className={"display-6"} style={{textAlign: "center"}}>{languageData.medicationText}</h2>
            <Form onSubmit={handleSubmit}>
                <Row style={{ margin: '3% 0 3% 0' }}>
                    <Col xs={2}></Col>
                    <Col xs={3}>
                        <Form.Group controlId="hour">
                            <Form.Label>{languageData.hour}</Form.Label>
                            <Form.Control
                                as="select"
                                value={hour}
                                onChange={(e) => setHour(e.target.value)}
                                style={{ textAlign: 'center' }}
                            >
                                {[...Array(24)].map((_, h) => {
                                    const hourString = h.toString().padStart(2, '0');
                                    return (
                                        <option key={hourString} value={hourString}>
                                            {hourString}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={2}></Col>
                    <Col xs={3}>
                        <Form.Group controlId="minute">
                            <Form.Label>{languageData.minute}</Form.Label>
                            <Form.Control
                                as="select"
                                value={minute}
                                onChange={(e) => setMinute(e.target.value)}
                                style={{ textAlign: 'center' }}
                            >
                                {[...Array(12)].map((_, m) => {
                                    const minuteValue = m * 5;
                                    const minuteString = minuteValue.toString().padStart(2, '0');
                                    return (
                                        <option key={minuteString} value={minuteString}>
                                            {minuteString}
                                        </option>
                                    );
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
                <Row>
                <Col></Col>
                <Col>
                    <Button type="submit" style={{ width: '100%' }} disabled={!hour || !minute}>
                        {languageData.nextBtn}
                </Button>
                </Col>
                <Col></Col>
                </Row>
            </Form>
        </Container>
    );
}

export default Medication;