import React, {useEffect, useState} from 'react';
import Question from "./Question";
import {useLocation, useNavigate} from "react-router-dom";
import FinishPage from "./FinishPage";
import {Button, Col, Container, ProgressBar, Row} from "react-bootstrap";
import quest_deq from '../assets/json/DEQ_questions.json';
import quest_pdss from '../assets/json/PDSS_questions.json';
import nfs_de from '../assets/json/NFS_questions_DE.json';
import nfs_en from '../assets/json/NFS_questions_EN.json';
import nfs_it from '../assets/json/NFS_questions_IT.json';
import nfs_fr from '../assets/json/NFS_questions_FR.json';


interface QuestionnaireProps {
    languageData : any;
}

interface Answer {
    question : number,
    answer : number
}
const Questionnaire: React.FC<QuestionnaireProps> = ({languageData})=>{
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const quest = params.get('quest');
    const username = (params.get('username'));
    const [questions, setQuestions] = useState<any>(null);
    const [userAnswers, setUserAnswers] = useState<Answer[]>([])
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [isQuestionnaireCompleted, setIsQuestionnaireCompleted] = useState(false);
    const checkLanguage = async () => {
        if(!username){
            navigate("/error");
        }
        const urlParams = new URLSearchParams(window.location.search);
        const usernameParam = urlParams.get('username');
        if (usernameParam) {
            try {
                const response = await fetch(`https://recpd.ch/api/patient/checkLanguage/${usernameParam}`);
                if (!response.ok) {
                    throw new Error('Failed to check if user is present')
                }
                const data = await response.json();
                return data;

            } catch (error) {
                console.log(error);
            } finally {
            }
        }
    }
    const fetchQuestions =  (language : string) => {
        try {
            let data = {};
            if(quest){

                if(quest === "deq"){
                    data = quest_deq.questionnaire;
                }
                else if(quest === "pdss"){
                    data = quest_pdss.questionnaire;
                }
                setQuestions(data)
            }
            else{

                let dataNfs = nfs_en.questionnaire;;
                if(language === "EN"){
                    dataNfs = nfs_en.questionnaire;
                }
                else if(language === "DE"){
                    dataNfs = nfs_de.questionnaire;
                }
                else if(language === "IT"){
                    dataNfs = nfs_it.questionnaire;
                }
                else if(language === "FR"){
                    dataNfs = nfs_fr.questionnaire;
                }
                setQuestions(dataNfs);
            }
        } catch (error) {
            console.error('Error loading questions:', error);
        }
    };

    useEffect(() => {
        checkLanguage().then((data) =>{
            fetchQuestions(data.language);
        } )
    }, []);

    const handleAnswer = (questionId: number, answer: number) => {
        setUserAnswers(prevAnswers => {
            // Check if the question already exists in the array
            const index = prevAnswers.findIndex(item => item.question === questionId);

            // If the question exists, update its answer
            if (index !== -1) {
                const updatedAnswers = [...prevAnswers];
                updatedAnswers[index].answer = answer;
                return updatedAnswers;
            } else {
                // If the question does not exist, add a new entry
                return [
                    ...prevAnswers,
                    { question: questionId, answer: answer }
                ];
            }
        });
    };

    const handleFinish = () => {
        setIsQuestionnaireCompleted(true);
        const requestBody = {
            username : username,
            answers : userAnswers,
            quest : quest
        };
        fetch('https://recpd.ch/api/questionnaires/upload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)

        }).then(response => {
            if(response.ok){
                setIsQuestionnaireCompleted(true);
            }
        }).catch(error => {
            console.log(error);
        })
        fetch('https://recpd.ch/api/questionnaires/uploadDB', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)

        }).then(response => {
            if(response.ok){
                setIsQuestionnaireCompleted(true);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const handleNextQuestion = () => {
        setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    };

    const handlePreviousQuestion = () => {
        setCurrentQuestionIndex(prevIndex => prevIndex - 1);
    };

    if (questions === null) {
        return <div>Loading...</div>;
    }

    if(isQuestionnaireCompleted){
        return (<FinishPage languageData={languageData} username={username}/>);
    }

    const isLastQuestion = currentQuestionIndex === questions.questions.length -1;
    const isFirstQuestion = currentQuestionIndex === 0;
    return (
        <Container>
            <Row>
                <Container className={"p-2"}>
                    <ProgressBar striped variant={"info"} max={questions.questions.length -1} now={currentQuestionIndex} >
                    </ProgressBar>
                </Container>

            </Row>
            <Question
                options={questions.options}
                question={questions.questions[currentQuestionIndex]}
                onAnswer={handleAnswer}
                selectedOptions={userAnswers[currentQuestionIndex]}/>
            <Container className={"p-3"}>
                <Row>
                    <Col>
                        <Button style={{width: "135px", height: "50px"}} variant="outline-primary" onClick={handlePreviousQuestion}  disabled={isFirstQuestion}>{languageData.prevBtn}</Button>
                    </Col>
                    <Col>
                        <Button style={{width: "135px", height: "50px"}} variant="outline-primary" onClick={handleNextQuestion} hidden={isLastQuestion} disabled={userAnswers[currentQuestionIndex]=== undefined}>{languageData.nextBtn}</Button>
                        {isLastQuestion && (<Button variant="outline-success" onClick={handleFinish} style={{width: "135px", height: "50px"}}
                                                    disabled={userAnswers[currentQuestionIndex]=== undefined}>{languageData.submitBtn}</Button>)}
                    </Col>
                </Row>


            </Container>

        </Container>
    )
};
export default Questionnaire;
