import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";

interface RecordingProps {
    languageData: any;
    identifier: string;
    username: string | null;
    onFinish : () => void;
    medication : string | null;
}
const Recording: React.FC<RecordingProps> = ({languageData, identifier, username, onFinish, medication}) => {
    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const mediaStreamRef = useRef<MediaStream | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const [showStartButton, setShowStartButton] = useState(true);
    const [showStopButton, setShowStopButton] = useState(false);
    const [disableStopButton, setDisableStopButton] = useState(true);
    const [recordingDuration, setRecordingDuration] = useState<number>(0);

    useEffect(() =>{
        let timerInterval: NodeJS.Timeout;
        if(recording){
            timerInterval = setInterval(() => {
                setRecordingDuration((prevDuration) => prevDuration + 1);
            }, 1000);
        }
        return () => {
            clearInterval(timerInterval);
        };
    }, [recording]);


    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaStreamRef.current = stream;
            mediaRecorderRef.current.ondataavailable = (e) => {
                audioChunksRef.current.push(e.data);
            }
            mediaRecorderRef.current.onstop = () => {
                const blob = new Blob(audioChunksRef.current, {type: 'audio/wav'})
                handleUpload(blob);
            };

            mediaRecorderRef.current.start();
            setRecording(true);
            setShowStartButton(false);
            setShowStopButton(true);
            setRecordingDuration(0);
            setTimeout(() => {
                setDisableStopButton(false);
            }, 10000);

        }).catch((err) => {
            console.log(err)
        })
    }

    const stopRecording = () => {
        const mediaRecorder = mediaRecorderRef.current;
        if(mediaRecorder){
            mediaRecorder.stop();
            mediaStreamRef.current?.getTracks().forEach((track) => track.stop());
            setRecording(false);
            setShowStopButton(false);
        }
        onFinish();
    };



    const handleUpload = async (audioBlob : Blob) => {
        try {
            const formData = new FormData();
            formData.append('recording', audioBlob);
            if (username) {
                formData.append('username', username);
            }
            if(identifier.length >= 1) {
                formData.append('identifier', identifier);
                if(medication !=  null){
                    formData.append('medication', medication);
                }
                let response = await fetch('https://recpd.ch/api/recordings/upload', {
                    method: 'POST',
                    body: formData,

                });

                if (!response.ok) {
                    throw new Error('Failed to upload recording');
                }
                response = await fetch('https://recpd.ch/api/recordings/uploadDB', {
                    method: 'POST',
                    body: formData
                });
                if (!response.ok) {
                    throw new Error('Failed to upload recording in DB');
                }
            }
        } catch (error) {
            console.log(error)
        }
    };


    return(
        <Container>
            <Row className={"justify-content-center"}>
                <Col  xs="auto">  {showStartButton && !recording && (
                    <Button style={{width : "200px", height: "62px"}} onClick={startRecording}>{languageData.startRecording}</Button>
                )}  {recording && (
                    <Container>
                        <p>{languageData.recordingText}: {recordingDuration} {languageData.seconds}</p>
                    </Container>
                )}  </Col>
                <Row  className={"justify-content-center"}>
                    <Col  xs="auto">
                        {showStopButton && (
                            <Button style={{width : "200px", height: "62px"}} disabled={disableStopButton} onClick={stopRecording}>{languageData.stopRecording}</Button>
                        )}
                    </Col>
                </Row>
            </Row>
        </Container>
    )
}

export default Recording;

