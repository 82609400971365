import React from "react";
import {Container} from "react-bootstrap";


interface ErrorProps {
    languageData: any;
}

const Error: React.FC<ErrorProps> = ({languageData})=>{
    return (
        <Container className={"p-3"}>
            <h3 style={{textAlign: "center"}}>{languageData.errorUrl}</h3>
        </Container>
    )

}
export default Error;