import React, {useEffect, useState} from "react";
import neutA1 from "../assets/img/neutA1.png";
import neutA2 from "../assets/img/neutA2.png";
import neutA3 from "../assets/img/neutA3.png";
import neutA4 from "../assets/img/neutA4.png";
import neutA5 from "../assets/img/neutA5.png";
import neutB1 from "../assets/img/neutB1.png";
import neutB2 from "../assets/img/neutB2.png";
import neutB3 from "../assets/img/neutB3.png";
import neutB4 from "../assets/img/neutB4.png";
import neutB5 from "../assets/img/neutB5.png";
import plA1 from "../assets/img/plA1.png";
import plA2 from "../assets/img/plA2.png";
import plA3 from "../assets/img/plA3.png";
import plA4 from "../assets/img/plA4.png";
import plA5 from "../assets/img/plA5.png";
import plB1 from "../assets/img/plB1.png";
import plB2 from "../assets/img/plB2.png";
import plB3 from "../assets/img/plB3.png";
import plB4 from "../assets/img/plB4.png";
import plB5 from "../assets/img/plB5.png";
import unplA1 from "../assets/img/unplA1.png";
import unplA2 from "../assets/img/unplA2.png";
import unplA3 from "../assets/img/unplA3.png";
import unplA4 from "../assets/img/unplA4.png";
import unplA5 from "../assets/img/unplA5.png";
import unplB1 from "../assets/img/unplB1.png";
import unplB2 from "../assets/img/unplB2.png";
import unplB3 from "../assets/img/unplB3.png";
import unplB4 from "../assets/img/unplB4.png";
import unplB5 from "../assets/img/unplB5.png";
import unplC1 from "../assets/img/unpleasant1.png"
import unplC2 from "../assets/img/unpleasant2.png"
import unplC3 from "../assets/img/unpleasant3.png"
import unplC4 from "../assets/img/unpleasant4.png"
import unplC5 from "../assets/img/unpleasant5.png"
import {Container, Image} from "react-bootstrap";

interface ShowImagePageProps {
    username: string | null;
    onFinish : (selectedImage: string) => void;
}

const ShowImagePage: React.FC<ShowImagePageProps> = ({username, onFinish}) => {
    const randomNeutral = [neutA1, neutA2, neutA3, neutA4, neutA5, neutB1, neutB2, neutB3, neutB4, neutB5];
    const randomPleasant = [plA1, plA2, plA3, plA4, plA5, plB1, plB2, plB3, plB4, plB5];
    const randomUnpleasant = [unplA1, unplA2, unplA3, unplA4, unplA5, unplB1, unplB2, unplB3, unplB4, unplB5, unplC1, unplC2, unplC3, unplC4, unplC5];
    const randomAll = randomNeutral.concat(randomPleasant).concat(randomUnpleasant);
    const [selectedImage, setSelectedImage] = useState<string>('');

    const selectCategory = async () => {
        try {
            const response = await fetch(`https://recpd.ch/api/patient/getImageSet/${username}`);
            if (!response.ok) {
                throw new Error('cannot get image set.');
            }
            const data = await response.json();
            let selectedArray = randomAll; // Default to random

            switch (data.image_set) {
                case 'neutral':
                    selectedArray = randomNeutral;
                    break;
                case 'pleasant':
                    selectedArray = randomPleasant;
                    break;
                case 'unpleasant':
                    selectedArray = randomUnpleasant;
                    break;
                case 'random':
                default:
                    selectedArray = randomAll;
                    break;
            }

            const randomIndex = Math.floor(Math.random() * selectedArray.length);
            setSelectedImage(selectedArray[randomIndex]);

        } catch (error) {
            console.error('Error fetching image set:', error);
        }

    }

    useEffect(() => {
        selectCategory();
    }, []);


    useEffect(() => {
        if(selectedImage !== '') {
            onFinish(selectedImage.split("/")[3].split(".")[0])
        }
    }, [selectedImage, onFinish]);

    return(
            <Image src ={selectedImage} style={{height: "auto", width: "300px"}} ></Image>


    )
}
export default ShowImagePage
