import React, {useEffect} from "react";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Button, Col, Container, Row} from "react-bootstrap";


interface HomeProps {
    languageData: any;
}

const Home : React.FC<HomeProps> = ({languageData}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const username = params.get('username');

    useEffect(() => {
        if(!username){
            navigate("/error");
        }
    }, [navigate, username]);

    return (



        <Container className="p-5">
            <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
            </Row>
            <Row>
                <Col ></Col>
                <Col >
                    <Link to={`/recording?username=${username}`}>
                        <Button style={{width: "200px", height:"75px", marginBottom: "10%"}} variant="primary">{languageData.buttonSpeech}</Button>

                    </Link>
                </Col>
                <Col ></Col>
            </Row>

            <Row>
                <Col ></Col>
                <Col >
                    <Link to={`/image?username=${username}`}>
                        <Button style={{width: "200px", height:"75px", marginBottom: "10%"}} variant="primary">{languageData.buttonImage}</Button>

                    </Link>
                </Col>
                <Col ></Col>
            </Row>

            <Row style={{marginTop : "20%"}}>
                <Col></Col>
                <Col>
                    <Link to={`/questionnaire?username=${username}`}>
                    <Button style={{width: "200px", height:"75px"}}>{languageData.buttonNFS}</Button>
                </Link></Col>
                <Col></Col>

            </Row>


        </Container>

    );
};

export default Home;