import React from "react";
import happySmiley from '../assets/Happy.png';
import neutralSmiley from '../assets/Neutral.png';
import okSmiley from '../assets/Ok.png';
import sadSmiley from '../assets/Sad.png';
import unhappySmiley from '../assets/Unhappy.png';
import {Row} from "react-bootstrap";
interface SmileyProps {
    onClick: (imageName: string) => void
}

const smileys = [sadSmiley, unhappySmiley, neutralSmiley, okSmiley, happySmiley  ];



const Smiley: React.FC<SmileyProps> = ({onClick}) => {
    const handleClick = (imageName: number) => {
        if (imageName === 0) {
            onClick("sad");
        } else if (imageName === 1) {
            onClick("unhappy");
        } else if (imageName === 2) {
            onClick("neutral");
        } else if (imageName === 3) {
            onClick("ok");
        } else if (imageName === 4) {
            onClick("happy");
        }


    }
    return (
        <div>
            {smileys.map((smiley, index) => (
                <Row key = {index} style={{ justifyContent: 'center', padding: "0 0 5% 0" }}>
                <img key={index} src={smiley} alt="Smiley" onClick={() => handleClick(index)} style={{ width: '30%', height: 'auto' }}/>
                </Row>
            ))}
        </div>
    )
};

export default Smiley;
