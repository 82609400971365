import React, {useEffect, useState, useRef} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import FinishPage from "./FinishPage";
import Recording from "./Recording";



interface RecordVoiceProps {
    languageData: any;
    identifier: string;
    username: string | null;
    instructionText : string;
    medication: string | null;

}

const RecordVoice: React.FC<RecordVoiceProps> = ({languageData, identifier, username, instructionText, medication}) => {
    const [isRecordingFinished, setIsRecordingFinished] = useState<boolean>(false);

    const onFinish = () => {
        setIsRecordingFinished(true);
    }

    return(
        <>{!isRecordingFinished ?(<>
            <Container className={"p-5"}>
                <h3 className={"display-6"} style={{textAlign: "center", paddingBottom: "5%"}}>{instructionText}</h3>
            </Container>
            <Container>
                <Recording languageData={languageData} identifier={identifier} username={username} onFinish={onFinish} medication={medication}></Recording>
            </Container>
        </>) : (<FinishPage languageData={languageData} username={username}/>)}

        </>
    )
}
export default RecordVoice;