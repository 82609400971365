import React, {useEffect, useState} from "react";
import PatientOverwiev from "./PatientOverview";
import {Button, Col, Container, Row, Image} from "react-bootstrap";
import Timer from "./Timer";
import recordVoice from "./RecordVoice";
import RecordVoice from "./RecordVoice";
import {useLocation} from "react-router-dom";
import ShowImagePage from "./ShowImagePage";
import Recording from "./Recording";

interface ImagePageProps {
    languageData: any;

}

const ImagePage : React.FC<ImagePageProps> = ({languageData}) => {
    const [timer, setTimer] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const username = params.get('username');
    const [isFinished, setIsFinished] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string>("");

    const showImage = () => {
            setTimer(true);
            setButtonClicked(true);
    }
    const handleTimerFinish = () => {
        setIsFinished(true);

    }

    const handleImageFinish = (image: string) => {
        setSelectedImage(image);
    }


    return(
        <Container className="p-5">

            <Row style={{marginTop : "10%"}}>
                <Col></Col>
                <Col>
                    <Button style={{width : "100%"}} onClick={() => showImage()} disabled={buttonClicked} hidden={isFinished}>Start</Button>
                    {(timer && !isFinished) && (
                        <Timer initialTime={10} onFinish={handleTimerFinish}/>
                    )}
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col> {(buttonClicked && !isFinished)&& (
                    <ShowImagePage username = {username} onFinish={handleImageFinish}/>
                )}</Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col> {isFinished && ( <RecordVoice languageData={languageData} username={username} identifier={selectedImage} instructionText={languageData.instructionText2} medication={null}/>)}</Col>
                <Col></Col>
            </Row>




        </Container>

    )

}

export default ImagePage