import React, {useEffect, useRef, useState} from "react";


interface TimerProps {
    initialTime: number;
    onFinish: () => void;
}

const Timer: React.FC<TimerProps> = ({initialTime, onFinish}) => {
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const requestRef = useRef<number | null>(null);
    const startTimeRef = useRef<number | null>(null);

    useEffect(() => {
        const startTime = performance.now();
        startTimeRef.current = startTime;

        const updateTimer = (currentTime: number) => {
            if (startTimeRef.current !== null) {
                const elapsedTime = Math.floor((currentTime - startTimeRef.current) / 1000);
                const newTimeLeft = initialTime - elapsedTime;

                if (newTimeLeft >= 0) {
                    setTimeLeft(newTimeLeft);
                    requestRef.current = requestAnimationFrame(updateTimer);
                } else {
                    setTimeLeft(0);
                    if (requestRef.current !== null) {
                        cancelAnimationFrame(requestRef.current);
                    }
                    onFinish();

                }
            }
        };

        requestRef.current = requestAnimationFrame(updateTimer);

        return () => {
            if (requestRef.current !== null) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [initialTime]);

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div>
            <p>Timer: {formatTime(timeLeft)}</p>
        </div>
    );

}


export default Timer;